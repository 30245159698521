$LogInColor: #355065;
$ButtonColor: #E05E5E;
$ButtonHoverColor: #BC4040;
$TextColor: rgba(255, 255, 255, 0.5); 
$ContainerColor: #10283A;
$navBar: #143e5e;
$NavBarTextHover:#82808F;

$black: black;
$white: white;
$becomeApartnerContainerColor: #FDF5DF;
$Orange: rgb(236, 188, 100);        