@import "../../Colors.scss";

.AdvantagesTitle {
    width: 100%;
    text-align: center;
    color: $white;
    padding-top: 120px;
    padding-bottom: 32px;
  font-size: 48px !important;
  

@media screen and (max-width: 480px) { 
    font-size: 34px !important;
    padding-bottom: 0px !important;
}


}

.AdvantagesSmallText {
    color: $TextColor;
    font-size: 18px !important;

    @media screen and (max-width: 480px) { 
        font-size: 16px !important;
    }
}

.AdvantagesSmallTextwhite {
    color: $white;
    font-size: 18px !important;
    @media screen and (max-width: 480px) { 
        font-size: 16px !important;
    }
}

.AdvantagesSmallTextContainerAdvantages {
    text-align: center;
    width: 100%;
    margin-bottom: 48px !important;

    @media screen and (max-width: 1200px) {     
        margin-bottom: 24px !important;
        padding: 16px !important;
    }
}

.AdvantagesContainer {
    padding-bottom: 200px !important;
  
    padding: 0;
  
    @media screen and (max-width: 1200px) {
      padding: 24px !important;
      margin-bottom: 180px !important;
      border-bottom: 1px solid #82808F !important;
    
    }
  }