@import '../../Colors.scss';


.LogoSliderWrapper {
   position: absolute;
    z-index: -1 !important;
    width: 100%;
    margin-top: -100px;
  
    @media screen and (max-width: 1200px) {     
        margin-top: -70px;
  
    }

    @media screen and (max-width: 600px) {     
        margin-top: 0px;
  
    }

    


 
.LogoStyle{
    margin: 12px !important;
    width: 120px !important;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
    filter: grayscale(100%);
    
    @media screen and (max-width: 600px) {     
        width: 90px !important;
  
    }

  

}


.MarqStyle1 {
    overflow: hidden !important;
    width: 100% !important;

    margin-bottom: -20px !important;

}

.MarqStyle2 {
    width: 100% !important;
    overflow: hidden !important;
}

}