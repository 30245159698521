.page-title {
  font-weight: bold; 
  margin-bottom: 32px; 
  text-align: center;
  color: #fff;
  margin-top: 32px;

  @media  (max-width: 475px) {
    font-size: 34px !important;
  }

}

.Events-link {
  color: #fff !important;
  text-decoration: none !important;
}

.EventSubTitle {
  text-align: center;
  font-weight: bold !important;
  margin-bottom: 48px !important;
  margin-top: 48px !important;
  color: #818085;
}

.EventSubTitleWhite {
  color: white;
}

.container {
  height: auto;
  display: flex;
  justify-content: center;
}

.flesh {
  width: 16px !important;
  height: 24px !important;
  font-size: 100px !important;
  filter: brightness(100%) !important;
  z-index: 10 !important;
  border: none !important;
  margin-left: 8px;
}

.row {
  box-sizing: border-box;
  flex-wrap: nowrap !important;
  height: 159px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  margin-bottom: 80px !important;
  margin-top: 80px !important;
  padding-left: 100px;
}

.big-box-row {
  margin-bottom: 100px;
  max-width: 100%;
}

.events-container {
  display: flex !important;
  width: 100%;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.EventsBox {
  max-width: 1200px;
  width: 100%;
}

.big-box, .small-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 30px;
  position: relative;
  z-index: 100;
  border: #818085 !important;
  padding: 0px !important;
}

.big-box {
  max-width: 100% !important;
  z-index: 1;
  border: 2px #ffffff !important;
  height: 230px;
  position: relative;
}

.small-box {
  max-width: 100% !important;
  width: 1065px;
  height: 230px;
  position: relative;
}

.logo {
  filter: brightness(100%) !important;
  top: 10%;
  margin-bottom: 1px;
  height: 100%;
  border-radius: 40% !important;
  z-index: 40;
  border: 3px solid #818085 !important;
  box-sizing: border-box;
}

.big-box .logo, .small-box .logo {
  // border-radius: 40% !important; 
  border: 1px solid #818085 !important;
}

.big-box img, .small-box img {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 26px;
  filter: brightness(40%);  
  z-index: 0;
  @media (max-width:1199px) {
    filter: brightness(20%);  
  }
}

.big-box:hover .image, .small-box:hover .image {
  transform: scale(1.2, 1.2);
  transition-duration: 1s;
  filter: brightness(20%) !important;
  //  @media (max-width:1200px) {
  //  filter: brightness(10%) !important;
  // }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 26px;
  transition: transform 1s ease-in-out;
}

.text-content{
  padding-top: 10px !important;
}

.image-container {
  z-index: 1;
  box-shadow: 0 0 0 1.3px #b9b8bc;
  transition: transform 0.3s ease;  
  position: relative;
  overflow: hidden; 
  width: 100%; 
  height: 100%; 
  border-radius: 26px; 
  border: 0.25px solid #818085;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.5);
  transition: transform 1s ease-in-out;
}

.text-overlay-l {
  position: absolute; 
  padding-left: 48px !important;
  left: -5px !important;  
  border-radius: 26px;
  font-size: 18px; 
  color: white; 
  z-index: 2; 
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
  width: 100%;
  height: 160px;
}

.text-overlay-l h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 240px;
  text-align: left;
}

.text-overlay-l p {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis !important; 
  margin-bottom: 1px;
  color: #818085;
  display: flex;
  width: 100%; 
}

.text-overlay-l img {
  filter: brightness(100%);
  border-radius: 19px !important;
  border: 2px red !important;
}

.text-overlay-l img.logo {
  filter: brightness(100%);
  border-radius: 26px !important;
  // border: 1px #818085 !important;
  width: 100%;
  
}

.text-overlay {
  position: absolute; 
  padding-left: 48px !important;
  left: -5px !important;  
  border-radius: 5px;
  font-size: 18px; 
  color: white; 
  z-index: 20; 
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
  width: 100%;
  height: 160px;
}

.text-overlay h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 240px;
}

.text-overlay p {
  font-size: 18px;
  margin-bottom: 1px;
  color: #818085;
  display: flex;
  white-space: nowrap; 
}

.text-overlay img {
  filter: brightness(100%);
  border-radius: 19px !important;
  border: 1px #818085 !important;
}

.text-overlay img.logo {
  filter: brightness(100%);
  border-radius: 29px !important;
  // border: 1px #818085 !important;
  width: 100%;
  display: block;
}

.text-overlay-info {
  white-space: nowrap;
}
@media (max-width:375px){
.css-1e7eu8g-MuiTypography-root {
  font-size: 16px !important;
}
}

@media (min-width:375px){
  .css-1e7eu8g-MuiTypography-root {
    font-size: 18px !important;
  }
  }
@media (max-width:375px) {
  .date-text {
    display: flex; 
    align-items: center; 
    justify-content: flex-start; 
    font-size: 18px !important;
    width: 190px !important;
  }
}
@media (min-width:375px) {
.date-text {
  display: flex; 
  align-items: center; 
  justify-content: flex-start; 
  font-size: 20px !important;
  width: 190px !important;
}
}

@media (max-width:375px) {
  .text-overlay p {
    font-size: 12px !important; 
    margin-bottom: 2px; 
    white-space: nowrap; 
    text-align: center; 
    width: calc(100% - 16px);
  }
}
@media (min-width:375px) {
  .text-overlay p {
    font-size: 14px !important; 
    margin-bottom: 2px; 
    white-space: nowrap; 
    text-align: center; 
    width: calc(100% - 16px);
  }
}
 

@media (max-width: 1199px) {
  .text-overlay-l, .text-overlay {
    flex-direction: row; 
    align-items: center; 
    justify-content: left; 
    padding-left: 0 !important; 
    left: 0 !important;  
    height: 90%;
    padding: 16px;
  }

  .text-overlay-logo {
    margin-bottom: 8px; 
  }

  .text-overlay-logo img {
    filter: brightness(100%);
    border-radius: 19px !important;
    width: 60px; 
    height: auto; 
    // padding-right: 18px;
  }

  .logo {
    filter: brightness(100%) !important;
    top: 10%;
    margin-bottom: 1px;
    height: 100%;
    border-radius: 28px !important;
    z-index: 40;
    border: 3px solid #818085 !important; 
    box-sizing: border-box;
    // padding-right: 15px;
    // padding-left: 15px;
    margin-left: 8px !important;
    display: flex;
  }

  .text-overlay-content {
    display: flex;
    flex-direction: column;
    align-items: center; 
  }

  .text-overlay-l h3 {
    font-size: 16px; 
    font-weight: bold;
    margin-bottom: 4px; 
    width: auto; 
    text-align: center; 
  }

  .text-overlay-l p {
    font-size: 14px; 
    margin-bottom: 2px; 
    white-space: nowrap; 
    text-align: center; 
  }

  // .text-overlay p {
  //   font-size: 12px; 
  //   margin-bottom: 2px; 
  //   white-space: nowrap; 
  //   text-align: center; 
  //   width: calc(100% - 16px);
  // }

  .text-overlay-logo img.logo {
    filter: brightness(100%);
    border-radius: 19px !important;
    border-color: 1px #818085 !important;
    width: 60px; 
    height: auto; 
    display: block;
    padding-right: 18px;
  }

  .text-overlay-info {
    white-space: nowrap;
    text-align: center; 
    padding-right: 26px !important;
  }

  .big-box, .small-box {
    width: 100% !important; 
    height: 120px !important;
    max-width: 5000px !important;
    margin-bottom: 20px;
  }

  .big-box img, .small-box img {
    width: 100%; 
    height: 100%; 
    object-fit: cover;
    display: flex;
    align-items: center;
  }

  .text-content {
    min-width: 150px;
    flex-direction: row; 
    margin-left: 24px;
    padding-top: 0px !important;
  }

  .css-1ld3b9g-MuiGrid-root > .MuiGrid-item {
    padding-left: 32px !important;
    padding-right: 0px !important;
    height: auto;
    padding-top: 1px !important;
  }

  // .css-1e7eu8g-MuiTypography-root{
  //   font-size: 18px !important;
  // }

  .flesh {
    margin-left: 1px;  
    height: 16px !important;
  }

  .event-box .small-box {
    top: 100px !important;
  }

  .logo {
    border: 2px solid #818085; 
  }

  .big-box .image, .small-box .image {
    filter: brightness(30%) !important; 
  }

}
