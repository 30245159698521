@import '../../Colors.scss';

.SportsToPlayTitle {
    width: 100%;
    text-align: center;
    color: $white;
    padding-top: 120px;
    padding-bottom: 32px;
    font-size: 48px !important;
    @media screen and (max-width: 480px) {     
        font-size: 34px !important;
        padding-bottom: 0px;
}



}

.SportsToPlaysmallText {
    color: $TextColor;
  font-size: 18px !important;

  @media screen and (max-width: 480px) {     
    font-size: 16px !important;
}


}

.SportsToPlaysmallTextwhite {
    color: $white;
    font-size: 18px !important;
    @media screen and (max-width: 480px) {     
        font-size: 15px !important;
    }
    
   

}

.SportsToPlaysmallTextContainer {
    text-align: center;
    width: 100%;
    margin-bottom: 48px !important;

    @media screen and (max-width: 480px) {     
  
        margin-bottom: 0px;
}
}

.SportsToPlayContainer{

    padding-bottom: 48px !important;

    @media screen and (max-width: 1200px) {     
        border-bottom: none !important;
        padding-bottom: 0px  !important;
}

@media screen and (max-width: 480px) {     
  
    padding-left: 24px!important; ;
    padding-right: 24px !important;
}

 }

 .Divider{
    background-color: rgba($white, 0.5);
    height: 1px  !important;
   width: 100% !important;
   margin-top: 40px !important;
 }