@import '../../Colors.scss';

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  margin-bottom: 60px;
  margin-top: 180px;
  box-sizing: border-box; 
}

.TestimonialsTitle {
  color: #FFF;
  font-weight: bold;
  line-height: 72px;
  text-align: center;
  @media  (max-width: 475px) {
    line-height: 6px;
    font-size: 34px !important;
  }
}

.TestimonialSubTitle {
  text-align: center;
  font-weight: bold !important;
  margin-bottom: 48px !important;
  margin-top: 48px !important;
  color: #818085;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.TestimonialSubTitleWight {
  color: #FFF;
}


.horizontalLine {
  border: none;
  height: 1px;
  background-color: #000; 
  margin: 20px 50px;
}

.testimonial-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden; 
  width: 100%; 
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;  
  gap: 20px;        
  justify-content:center; 
  width: 100%;      

  @media (min-width: 1200px) {
    width: 90vw !important;
    justify-content:flex-start; 
  }



  @media (min-width: 1400px) {
    width: 1100px !important;
  }

}


.testimonial-description{
  font-size: 18px !important;
  width: 290px !important;
  @media (max-width:375px) {
    font-size: 16px !important;
    width: 224px !important;
  }
}






.testimonial-box .rating {
  margin-bottom: 10px;
  overflow: hidden !important;
  display: flex;
  justify-content: flex-start;
}

.testimonial-box .star {
  font-size: 1.5rem; 
  margin: 0 2px; 
}

.show-more-button {

    border-radius: 8px !important;
    text-transform: none !important;
    margin-top: 32px !important;
    background-color: transparent !important;
    border: 1px solid  $ButtonColor !important;   
    color: $ButtonColor  !important;
    font-size: large !important;
    white-space: nowrap;
    padding: 12px 24px !important;
    font-size: 16px !important;



}

.show-more-button:hover {
  background-color: #1C1C1C !important;
}
.testimonial-box  {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #81808C; 
  border-radius: 26px;     
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  padding: 24px;   
  text-align: center;  
  color: #808087;  
  height: 600px; 
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  margin-bottom: 15px;
  text-align: left;

  @media (max-width:375px) {
    width: auto;
  }
  // @media (min-width:375px) {
  //   font-size: 20px;
  // }
}


.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  z-index: 10;
  cursor: pointer;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.testimonial-link {
  color: white; 
  text-decoration: none; 
  font-size: 20px !important; 
  @media (max-width:375px) {
    font-size: 18px !important; 
  }
}

.TestimonialboxTitleBot{
  color:white;
  font-size: 18px !important;
  margin-left: 8px !important;

  @media (max-width:450px) {
    //font-size: 18px !important; 
  }
}
