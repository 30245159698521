@import '../../Colors.scss';

.primaryBox {
    padding-top:8px !important;
    border-radius: 25px !important;
    padding-left: 0 !important;
    height: auto !important;
    margin-left:16px !important;
    margin-bottom: 32px !important;
 
    @media screen and (max-width: 480px) {
       
        margin-bottom: 32px !important;
        padding-top:4px !important;
        padding:0px !important;
        margin-left:0px !important;
    }
}

.boxTitle {
    color: white !important;
    line-height: 1 !important;
 margin-bottom: 8PX !important;
 font-size: 24px !important;
    @media screen and (max-width: 480px) {
        font-size: 20px !important;
        text-wrap:wrap !important;
    }

}

.boxText {
    color: $TextColor;
font-size: 18px !important;
 

    @media screen and (max-width: 480px) {
        font-size: 16px !important;
    }
}

.imageStyle {
    border: 1px solid rgba($white , 0.5) !important;
    padding: 16px !important;
    border-radius: 25px !important;
    margin-right: 16px !important;
    height: 32px !important;
    width: 32px !important;
    box-sizing:content-box !important;

  


}