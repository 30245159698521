@import './Colors.scss';

@font-face {
  font-family: popins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: popinsBold;
  src: url(./assets/fonts/Poppins-ExtraBold.ttf);
}

.popins-bold {
  font-family: popinsBold, popins, sans-serif !important;
  /* Additional styles if needed */
}

* {
  font-family: popins !important;
}

body {
  margin: 0;
 background: linear-gradient(#050021, $black) !important;
   
}

html {
  scroll-behavior: smooth;

}

