@import"../../Colors.scss";




.FooterText,.FooterTextMidle {
    color: $white;
    margin-left: 48px !important; 
    font-weight: normal !important;
 text-decoration: none !important;
    @media screen and (max-width: 899px) { 
        margin-left: 0px !important; 
       margin-top: 0px !important;
       
    }
}
.FooterTextMidle{

  
    @media screen and (max-width: 899px) { 
        margin-bottom: 24px !important; 
       margin-top: 24px !important;
       
    }
}


.BetDevelopper{
    color:$TextColor !important;
    margin-top: 40px !important;
    margin-right: 30px !important;

    @media screen and (max-width: 899px) { 
        margin-top: 0px !important;
        margin-right: 0px !important;
       
    }
}

.FooterText:hover,.FooterTextMidle:hover {
    opacity: 50% !important;
    cursor: pointer;
}

.FooterLogo{
    width: 87px !important;
    height: 45px !important;
margin-bottom: 8px !important;

}
.FooterGrid {
   
  
    width: 100% !important;
}


.BigWaletGridLeft {
   
    align-items: center !important;
    font-weight: normal !important;
    height: 150px !important;


    @media screen and (max-width: 899px) { 
        height: 72px !important;
     }
}



.TextOfUpdateRight {
    align-self: flex-end !important;
    margin-left: 0 !important;
    color: $white !important;
    align-items: flex-end !important;
    margin-bottom: 32px !important;
}

.custom-label {
    color: grey;
}

.EmailAdressInput {

    border-radius: 15px !important;
   
    width: 280px !important;
    margin-right: 24px !important;
}


.BigWaletGridRight {
    margin-right: 40px !important;
    width: 100% !important;
    display: flex !important;
   padding-top: 16px !important;

   @media screen and (max-width: 899px) { 
    padding-top: 32px !important;
    padding-bottom: 32px !important;
}
  }
  
  @media screen and (max-width: 899px) { 
    .BigWaletGridRight {
      display: block !important;
    }
  }

.AllRightReservedUp,.AllRightReservedDown{
    font-size: 12px !important;
    color:$NavBarTextHover !important;  
}

.AllRightReservedUp{
    display: block !important;

    @media screen and (max-width: 899px) { 
        display: none  !important;
}
}


.AllRightReservedDown{
    display: none !important;

    @media screen and (max-width: 899px) { 
        display: block  !important;
       text-align: center !important;
}
}

.FooterLink{
    text-decoration: none !important;
    margin-bottom: 16px !important;
}

.FooterContainer{
    width: 100%!important;
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
    padding-top: 48px !important;

    @media screen and (max-width: 899px) { 
        padding-bottom: 48px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
}

}
//