
@import '../../Colors.scss';
.LanguagesContainer {
    background-color: transparent !important;
    color: white !important;
    border: 1px solid transparent; 
    margin-left: 8px !important;
    font-size: 16px !important;
    transition: border 0.3s ease, color 0.3s ease; 
    border-radius: 8px !important;
}

.LanguagesContainer:hover {
    border: 1px solid white !important;
}

.imgStyle{
    height: 100% !important;
    width: 100% !important;
}

.LanguagesOption{
    color:black !important;
}

#fr {
    background-image: url("../../assets/flags/fr.png") !important;
    width: 100% !important;
    background-color: red ;
}

#en {
    background-image: url("../../assets/flags/en.png") !important;
    width: 100% !important;
    background-color: green ;
 }

.LanguagesItem{

    padding: 0;
     width: 80px;
     background: linear-gradient(#050021, $black) !important;
}