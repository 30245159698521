@import '../../Colors.scss';




.StartTheRaceContainer {
    margin-top: 175px !important;
padding-left: 24px !important;
padding-right: 24px !important;








.StartTheRaceLeftContainer,
.StartTheRaceRightContainer {


    @media (max-width: 1200px) {

        margin-left: 64px !important;
    }

    @media (max-width: 600px) {

        margin-left: 0px !important;
        margin-bottom: 64px !important;
    }

    .StartTheRacebigTextProgram,
    .StartTheRaceTypewriter {
        color: $TextColor;
        font-weight: bold !important;
        font-size: 56px !important;
        //  font-family: popinsBold, popins, sans-serif !important;
        @media (max-width: 480px) {
            font-size: 48px !important;
            text-wrap: wrap !important;
        }
    
    }
    .StartTheRaceTypewriter {
        color: white !important;
        text-wrap: nowrap !important;

        @media (max-width: 850px) {
         
            text-wrap: wrap !important;
        }
    }
    
    .StartTheRacesmallText {
        color: rgba($white, 0.5);
        font-size: 18px !important;
    
        @media (max-width: 502px) {
            display: inline !important;
        }
    
        .StartTheRacesmallTextLink {
            color: white !important;
            font-size: 18px !important;
        }
    }
    
    .StartTheRaceLink {
    
        text-decoration: none !important;
    }

    .StartTheRacesmallTextLink:hover {
        cursor: pointer;
        color: $NavBarTextHover !important;
        font-size: 18px !important;
    }

    .StartTheRacebecomeAPartner {
        border-radius: 8px !important;
        text-transform: none !important;
        margin-top: 32px !important;
        background-color: $ButtonColor !important;
        color: $black !important;
        font-size: large !important;
        white-space: nowrap;
        padding: 12px 24px !important;
        font-size: 16px !important;
    
    
     
    
    }
    
    .StartTheRacebecomeAPartner:hover {
        background-color: $ButtonHoverColor !important;
    }
    
    .StartTheRaceJoinOurText,
    .StartTheRacebigText,
    .StartTheRacebigTextProgram {
        line-height: 1.3 !important;
    }


    
.StartTheRaceTowPhoneImages {
    margin-left: 40px !important;
    width: 500px !important;
    height: 500px !important;

    @media screen and (max-width: 1200px) {

        margin-top: 0px !important;
        margin-left: 0px !important;
    }
}

}
}






