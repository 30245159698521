@import '../../Colors.scss';



.ContactUsTitle {
    color: $TextColor;
    font-size: 48px !important;
  
  
    
    @media screen and (max-width: 480px) { 
        font-size: 34px !important;
     
    }

  
}

.partnersNabaffiliates {
    background-color: $ButtonColor !important;
    margin-top: 32px !important;
    color: black !important;
    width: 240px !important;
    height: 50px !important;
    margin-bottom: 80px !important;
    border-radius: 30px !important;
    font-weight: normal !important;
    text-transform: none !important;

    @media screen and (max-width: 552px) {     
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }
}

.partnersNabaffiliates:hover {

    background-color: $white !important;
}

.smallTextContainerContactUs {
    color: $TextColor;
    text-align: left !important;

    @media screen and (max-width: 480px) {     
        font-size: 14px !important;
    
    }

    
 

}

.ContactUsTitleWitheColor {
    color: $white !important;
    font-size: 48px !important;
  
  
    
    @media screen and (max-width: 480px) { 
        font-size: 34px !important;
     
    }

  
}


.ContactUsButtonDiv{


    @media screen and (max-width: 1200px) {     
        text-align:center !important;
    
    }
   
     width:100% !important;
}


.ContactUsleftGridContainer {
    margin-top: 24px;
    margin-bottom: 32px ;

    @media screen and (max-width: 552px) {     
        margin-bottom: 16px !important;
        margin-top: 12px !important;
    }
}

.ContactUsSendUsMessage {
    color: $white !important;
    font-size: 24px !important;
     margin-bottom: 16px !important;
  
    
  

 
}

.ContactUsInputTextField {
    border: 1px solid $NavBarTextHover !important;
    color: $NavBarTextHover !important;
    border-radius: 16px !important;
    width: 100% !important;
    height: 60px !important;
    margin-bottom: 8px !important;
    outline: none !important;
    padding-left: 16px !important;

    @media screen and (max-width: 552px) {     
        height: 50px !important;
      
    }
    
    @media screen and (max-width: 480px) { 
        height: 45px !important;
     
    }

   


}

.ContactUsTextAreaOfContactUs {

    width: 100% !important;
    margin-bottom: 24px !important;
    border-radius: 20px !important;
    outline: none !important;
    border: 1px solid $NavBarTextHover !important;
   



}

.ContactUsSendRequest {
    background-color: $ButtonColor !important;
    border-radius: 8px !important;
    color: black !important;
    width: 154px !important;
    height: 60px !important;
    margin-top: 32px !important;
  font-size: 16px !important;
  text-transform: none !important;


   
}

.ContactUsSendRequest:disabled {
    opacity: 0.8;
}

.ContactUsSendRequest:hover {
    background-color: $ButtonHoverColor !important;
}

.ContactUsiHaveReadAndAgreed,
.ContactUsiHaveReadAndAgreedUnderline {
    color: $TextColor;
    font-size: 14px !important;
    display:inline !important;

    
   
}

.ContactUsihaveReadAndAgreedContainer{
    width: 50% !important;
   margin-top: 16px !important;

   @media screen and (max-width: 480px) {     
    width: 70% !important;
}

@media screen and (max-width: 336px) {     
    width: 100% !important;
}

}
.ContactUsiHaveReadAndAgreedUnderline {
    text-decoration: underline;
}


.ContactUsiHaveReadAndAgreedUnderline:hover {
    cursor: pointer !important;
}

.CheckBoxContactUs {
    background-color: $ButtonColor !important;
    border-radius: 8px !important;
    height: 32px !important;
    width: 32px !important;

}

.ContactUsContainer {
  border: 1px solid $NavBarTextHover ;
  padding: 90px !important;
  border-radius: 60px !important;
  
  
  @media screen and (max-width: 1200px) {     
    border: none !important ;
      padding: 60px !important;
      border-bottom: 1px solid #82808F !important;
      border-radius: 0px !important;
    }
    @media screen and (max-width:600px) {
        padding: 30px !important;
        border: none ;
    }
    
    @media screen and (max-width: 480px) {     
     padding: 24px !important; 
     padding-bottom: 64px !important;
    }

}

.ContactUsTitleContainer {
    margin-bottom: 16px !important;


    
    @media screen and (max-width: 480px) { 
        margin-bottom: 0px !important;
        margin-top: 120px !important;
    }
}

.ContactUssmallTextContainer{
 
    width: 74%;

    @media screen and (max-width: 480px) {     
        width: 80%;
        margin-bottom:16px !important;
        margin-top:   16px  !important;
    }
}

.ContactUsFeelFree{
    color:$TextColor !important;
    font-size: 20px !important;
}

.ContactUsFeelFreewhite{
    color: white !important;

    @media screen and (max-width: 480px) {     
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    
  
}

 .ContactUsSendRequest {
        border-radius: 8px !important;
        text-transform: none !important;
        margin-top: 32px !important;
        background-color: $ButtonColor !important;
        color: $black !important;
        font-size: large !important;
        white-space: nowrap;
        padding: 12px 24px !important;
        font-size: 16px !important;
    
    
        .ContactUsSendRequest:hover {
            background-color: $ButtonHoverColor !important;
        }
    }
 