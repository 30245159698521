@import '../../Colors.scss';

.TermsAndConditionsBox{
    overflow: scroll !important ;
    height: 3700px !important;

    
  
}
.TermsAndConditionsContainer{

    border: 1px solid $TextColor;
    border-radius: 48px !important;
 
    margin-top: 160px !important;
    padding: 140px !important;

    @media screen and (max-width: 1000px) {
padding: 70px !important;
    }


    @media screen and (max-width: 620px) {
        padding: 32px !important;
        border: 0px ;
            }
}

.TermsAndConditionsBox::-webkit-scrollbar {
    width: 0;  
}


.TermsAndConditionsBigTitle{
    font-size: 48px !important;
    font-weight: bold !important;
    color: $TextColor !important;
    line-height: 1 !important;
    margin-bottom: 16px !important;
    @media screen and (max-width: 600px) {
        font-size: 37px !important;
      
            }
}

.TermsAndConditionsParagraphTitle,.TermsAndConditionsParagraphTitleDefinition
    {
    color:$white !important;
   padding: 24px 0px !important;
   font-size: 18px !important;
}

.TermsAndConditionsParagraphParagraphe{
    color: $TextColor;
    font-size: 18px !important;
}

.TermsAndConditionsParagraphTitleDefinition{
    padding-bottom: 0px !important;
}
