@import "./Colors.scss";
*{
    // box-sizing: content-box!important ;
    --bs-dropdown-min-width: 0px !important;
}

.BodyContainer {
   
    margin-right: 0 !important;

    @media screen and (min-width: 1200px) {
        max-width: 100% !important
    }
}

.GridContainer {
    margin: 0 !important;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
 
}


::-webkit-scrollbar {
    width: 5px;

}

::-webkit-scrollbar-thumb {
    background: $LogInColor !important;
    border-radius: 0px;

}



