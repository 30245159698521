@import '../../Colors.scss';




@media screen and (max-width: 1200px) {
    .BecomeAPartnerSticky {
        position: relative !important;


    }
}




.BecomeAPartnerContainer {
 
    position: relative !important;
    top:60px !important;
    margin-top: 120px !important;
  

    @media screen and (max-width: 1200px) {   
       
        margin-top: 80px !important;
        padding: 24px !important;
        top:80px !important;
}

@media screen and (max-width: 600px) { 
    padding-bottom: 0px !important;
    margin-left: 0px !important;
}

}

.BecomeAPartnerTitleGray {
    color: $TextColor;
    margin-right: 12px !important;
    font-size:48px !important;

    @media screen and (max-width: 480px) { 
        font-size:34px !important;
}


}

.BecomeAPartnerTitleWhite {
    color: $white;
    font-size: 48px !important;

 

@media screen and (max-width: 480px) { 
    font-size: 34px !important;
}

}

.BecomeAPartnerTexteWhite {
    color: $white ;
    font-size: 18px !important;


@media screen and (max-width: 480px) { 
    font-size: 16px !important;
}

}

.BecomeAPartnerTexteWhite:hover {
    color: $NavBarTextHover !important;
    cursor: pointer !important;

}


.BecomeAPartnerTexteGray {
    color:white !important;
    opacity: 50% ;
    
    font-size: 18px !important;

 

@media screen and (max-width: 480px) { 
    font-size: 16px !important;
}
}

.BecomeAPartnerTexteContainer {
    width: 80% !important;
    margin-top: 32px !important;
    margin-bottom: 40px !important;

    @media screen and (max-width: 600px) {     
        margin-top: 8px !important;
        width: 100% !important;
    }


}


.BecomeAPartnerSticky {
    position:sticky;
    top: 15%;
    align-self: self-start;
    margin-bottom: 64px !important;
}

.BecomeAPartnerTitleContainer{
    margin-bottom: 32px!important;
}