@import "~../../Colors.scss";




.NavBarAppBar {
    background-color: #050021 !important;
    position: fixed;
    border-bottom: 1px solid rgba($white, 0.5) ;



.NavBarLogo,
.NavBarHumberg {
    width: 100px !important;
    height: 52px !important;
    padding-top: 24px !important;
    padding-bottom: 24px !important;
 box-sizing:content-box !important;

}

.NavBarHumberg{
    @media screen and (max-width: 1200px) {

        width:24px !important;
 
     
    }
    
}

.NavBarLogo:hover{
    cursor: pointer !important;
}

.NavBarHumberg:hover {
    cursor: pointer !important;
}

.NavBarLogin,.NavBarDrawerLogin {
    background-color: transparent !important;
    border: 1px solid $NavBarTextHover !important;
    padding: 6px 14px !important;
    color: $white !important;
    text-transform: none !important;
    margin-right: 8px !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    display: inline !important;
    margin-left: 8px !important;
    @media screen and (max-width: 480px) {
        padding: 4px 12px  !important;
        font-size: 11px !important;
    }

}

.NavBarLogin:hover {
    border: 1px solid $white !important;
}


.NavBarSignUp,.NavBarDrawerSignUp {
    background-color: $ButtonColor !important;
    padding: 6px 14px  !important;
    color: black !important;
    text-transform: none !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    border: 1px solid $ButtonColor !important;
    @media screen and (max-width: 480px) {
        padding: 4px 12px  !important;
        font-size: 11px !important;
       
    }

}



.NavBarSignUp:hover {
    background-color: $ButtonHoverColor !important;
    border: 1px solid $ButtonHoverColor !important;
}

.NavBarWords {
    display: inline;
    margin-right: 28px !important;
    font-size: 16px !important;
text-decoration: none !important;
color:$white !important;
}

.NavBarWords:hover {
    cursor: pointer !important;
    opacity: 50% !important;

}




.NavBarLink{
    text-decoration: none !important;
}




}

.NavBarDrawerContainer{

    width: 280px !important;
    border-right: 1px solid rgba(255, 255, 255, 0.5) !important;
    background-color: #050021 !important;
    height: 100% !important;
    color: $white !important;

    .NavBarXButton{
        margin: 24px !important;
    }
    .NavBarButtonContainer{
        padding-top:48px !important;
        padding-bottom:48px !important; ;
    }
    
    .NavBarWordsDrawer{
        text-decoration: none !important;
        color:$white !important;
        display: block !important;
        margin-bottom: 16px !important;
        margin-left: 16px !important;
    }

   

   .NavBarDrawerSignUp {
        background-color: $ButtonColor !important;
        padding: 6px 14px  !important;
        color: black !important;
        text-transform: none !important;
        font-size: 16px !important;
        border-radius: 8px !important;
        border: 1px solid $ButtonColor !important;
        @media screen and (max-width: 480px) {
            padding: 4px 12px  !important;
            font-size: 11px !important;
           
        }
    }
        .NavBarDrawerSignUp,.NavBarDrawerLogin{
            width: 88% !important;
            margin-left: 16px !important;
            margin-right: 16px !important;
            height: auto !important;
            font-size: 16px !important;
        }
    
    
    .NavBarDrawerLogin {
        background-color: transparent !important;
        border: 1px solid $NavBarTextHover !important;
        padding: 6px 14px !important;
        color: $white !important;
        text-transform: none !important;
        font-size: 16px !important;
        border-radius: 8px !important;
        display: inline !important;

        margin-bottom: 8px !important;
        @media screen and (max-width: 480px) {
            padding: 4px 12px  !important;
           
        }
    
    }
    
    .NavBarSignUp:hover {
        background-color: $ButtonHoverColor !important;
        border: 1px solid $ButtonHoverColor !important;
    }
    
}

.AllRightReserved{
   margin-left: 16px !important;
   margin-right: 16px !important;
   opacity: 50% !important;
   font-size: 14px !important;
}